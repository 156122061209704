interface Presentation {
  office: string;
  name: string;
  nickname: string;
  description: string;
  photo: string;
}

export const presentation: Presentation = {
  office: 'Full Stack Developer',
  name: 'Samuel Cunha',
  nickname: 'SawCunha',
  description: `Um dev full stack mineiro em busca dos mais diversos desafios e com paixão pelo conhecimento de novas tecnologias e amantes de jogos.`,
  photo:
    'https://media.licdn.com/dms/image/v2/C4E03AQH-o21darxN4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622843574081?e=1738195200&amp;v=beta&amp;t=KBgYRBgQaQ292LjZaPPVwtXHYT19j1jmF72bWCIGgDc'
};
